import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, ProgressBar, Form, Toast } from 'react-bootstrap';
import { fetchExamNames, fetchExamDetails, createTest, getAllTests } from './api';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/DashboardChatWindow.css';

const Dashboard = ({ user }) => {
    const [exams, setExams] = useState([]);
    const [selectedExam, setSelectedExam] = useState(null);
    const [chatLog, setChatLog] = useState([]);
    const [sectionData, setSectionData] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({});
    const [testList, setTestList] = useState([]);
    const [creatingNewTest, setCreatingNewTest] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const allTests = await getAllTests(user.email);
                setTestList(allTests.tests);
            } catch (error) {
                console.error('Error fetching test data:', error);
            }
        };

        fetchData();
    }, [user.email]);

    const handleCreateNewTest = async () => {
        try {
            const examNames = await fetchExamNames();
            setExams(examNames);
            setCreatingNewTest(true);
            setChatLog([
                { type: 'system', content: 'Please select an exam to create a new test.', style: { backgroundColor: '#e0e0e0' } },
                ...examNames.map(name => ({
                    type: 'system',
                    content: name,
                    style: { backgroundColor: '#f9f9f9' },
                    action: () => handleExamSelection(name),
                }))
            ]);
        } catch (error) {
            console.error('Error fetching exam names:', error);
        }
    };

    const handleExamSelection = async (examName) => {
        try {
            const examDetails = await fetchExamDetails(examName);
            setSelectedExam(examDetails);
            setSectionData(examDetails.Subjects || {});
            setChatLog([
                ...chatLog,
                { type: 'system', content: `You selected: ${examName}`, style: { backgroundColor: '#e0e0e0' } },
                { type: 'system', content: 'Please provide the details for the test.', style: { backgroundColor: '#e0e0e0' } }
            ]);
        } catch (error) {
            console.error('Error fetching exam details:', error);
        }
    };

    const handleTopicChange = (section, topic) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [section]: {
                ...(prevOptions[section] || { topics: {}, difficulty: {} }),
                topics: {
                    ...(prevOptions[section]?.topics || {}),
                    [topic]: !prevOptions[section]?.topics?.[topic],
                },
            },
        }));
    };

    const handleDifficultyChange = (section, difficulty) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [section]: {
                ...(prevOptions[section] || { topics: {}, difficulty: {} }),
                difficulty: {
                    ...(prevOptions[section]?.difficulty || {}),
                    [difficulty]: !prevOptions[section]?.difficulty?.[difficulty],
                },
            },
        }));
    };

    const handleQuestionNumberChange = (section, value) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [section]: {
                ...(prevOptions[section] || { topics: {}, difficulty: {}, questionCount: 0 }),
                questionCount: Math.min(value, sectionData[section]?.['Number Of Questions'] || 0),
            },
        }));
    };

    const handleSubmit = async () => {
        const finalJson = {
            "Name": selectedExam?.Name || "",
            "Subjects": {}
        };

        Object.entries(selectedOptions).forEach(([section, { topics, questionCount, difficulty }]) => {
            finalJson.Subjects[section] = {
                "Topics": Object.keys(topics).filter(topic => topics[topic]),
                "Number Of Questions": questionCount || 0,
                "Difficulty Level": Object.keys(difficulty).filter(level => difficulty[level])
            };
        });

        setLoading(true);
        try {
            await createTest(user.email, finalJson);
            setToastMessage('Test created successfully');
            setShowToast(true);
            setTimeout(() => {
                navigate('/dashboard'); // Reload the dashboard
            }, 2000); // Delay navigation to allow toast to show
        } catch (error) {
            console.error('Error creating test:', error);
            setToastMessage('Error creating test. Please try again.');
            setShowToast(true);
        } finally {
            setLoading(false);
        }
    };

    const renderOptions = () => {
        return Object.entries(sectionData).map(([section, data]) => (
            <Card key={section} className="mb-4">
                <Card.Header as="h5">{section}</Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={12} md={4}>
                            <strong>Topics:</strong>
                            {data.Topics.map(topic => (
                                <Form.Check
                                    key={topic}
                                    type="checkbox"
                                    id={`${section}-${topic}`}
                                    label={topic}
                                    onChange={() => handleTopicChange(section, topic)}
                                    checked={!!selectedOptions[section]?.topics?.[topic]}
                                    className="mb-2"
                                />
                            ))}
                        </Col>
                        <Col xs={12} md={4}>
                            <strong>Difficulty Level:</strong>
                            {['Easy', 'Medium', 'Hard'].map(difficulty => (
                                <Form.Check
                                    key={difficulty}
                                    type="checkbox"
                                    id={`${section}-${difficulty}`}
                                    label={difficulty}
                                    onChange={() => handleDifficultyChange(section, difficulty)}
                                    checked={!!selectedOptions[section]?.difficulty?.[difficulty]}
                                    className="mb-2"
                                />
                            ))}
                        </Col>
                        <Col xs={12} md={4}>
                            <strong>Number of Questions:</strong>
                            <Form.Control
                                type="number"
                                min="0"
                                max={data['Number Of Questions']}
                                value={selectedOptions[section]?.questionCount || data['Number Of Questions']}
                                onChange={(e) => handleQuestionNumberChange(section, e.target.value)}
                                className="mt-2"
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        ));
    };

    const renderChatInterface = () => {
        return (
            <div className="chat-container">
                <div className="chat-messages">
                    {chatLog.map((log, index) => (
                        <div key={index} className="chat-message" style={log.style || {}}>
                            <p>{log.content}</p>
                            {log.action && <Button variant="primary" onClick={log.action}>Select</Button>}
                        </div>
                    ))}
                    {selectedExam && (
                        <>
                            <div className="chat-message user-message">
                                <p>{selectedExam.Description}</p>
                            </div>
                            {renderOptions()}
                            <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                                {loading ? 'Creating...' : 'Create Test'}
                            </Button>
                        </>
                    )}
                </div>
            </div>
        );
    };

    const renderTestList = () => {
        return (
            <>
                <h4 className="text-center mb-4">Your Tests</h4>
                <div className="test-tiles">
                    {testList.map(test => (
                        <Card key={test.testId} className="mb-4 test-tile">
                            <Card.Body>
                                <Card.Title>{test.testDetails}</Card.Title>
                                <Card.Text>
                                    Created: {new Date(test.creationTime).toLocaleString()}<br />
                                    Last Updated: {new Date(test.lastUpdateTime).toLocaleString()}<br />
                                    Status: {test.status}<br />
                                    {test.status === 'In progress' && (
                                        <>
                                            Progress: {test.progress}<br />
                                            <ProgressBar now={parseInt(test.progress.split('/')[0])} max={parseInt(test.progress.split('/')[1])} />
                                        </>
                                    )}
                                    {test.status === 'Completed' && (
                                        <>
                                            Score: {test.score}<br />
                                            Incorrect Questions: {test['Incorrect questions'].join(', ')}
                                        </>
                                    )}
                                </Card.Text>
                                <Button
                                    variant={test.status === 'In progress' ? 'success' : test.status === 'Not started' ? 'primary' : 'secondary'}
                                    onClick={() => handleTestAction(test)}
                                >
                                    {test.status === 'In progress' ? 'Resume' : test.status === 'Not started' ? 'Start' : 'Review'}
                                </Button>
                            </Card.Body>
                        </Card>
                    ))}
                </div>
                <Button variant="primary" onClick={handleCreateNewTest}>Create New Test</Button>
            </>
        );
    };

    const handleTestAction = (test) => {
        if (test.status === 'In progress') {
            console.log('Resuming test:', test.testId);
        } else if (test.status === 'Not started') {
            console.log('Starting test:', test.testId);
        } else if (test.status === 'Completed') {
            console.log('Reviewing test:', test.testId);
        }
    };

    return (
        <Container>
            <Row>
                <Col>
                    {creatingNewTest ? renderChatInterface() : renderTestList()}
                </Col>
            </Row>
            <Toast 
                show={showToast} 
                onClose={() => setShowToast(false)} 
                bg={toastMessage.includes('success') ? 'success' : 'danger'} 
                delay={3000} 
                autohide
            >
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
        </Container>
    );
};

export default Dashboard;
