import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/styles.css';
const ExamHeader = ({ examName, duration, examStarted }) => {
    return (
        <div className="exam-header">
            <div className="exam-title">{examName}</div>
            {examStarted && <div className="exam-duration">Time Left: {duration} minutes</div>}
        </div>
    );
};

ExamHeader.propTypes = {
    examName: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    examStarted: PropTypes.bool.isRequired,
};

export default ExamHeader;
