import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col, InputGroup, FormControl, Alert } from 'react-bootstrap';
import { FaThumbsUp, FaThumbsDown, FaHeart } from 'react-icons/fa';
import examApi from './api'; // Import API functions
import '../../assets/css/styles.css'; // Ensure your CSS file is imported

const QuestionPage = () => {
    const navigate = useNavigate();
    const { examId } = useParams(); // Assuming you are using examId from params
    const [examData, setExamData] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [notes, setNotes] = useState('');
    const [liked, setLiked] = useState(false);
    const [disliked, setDisliked] = useState(false);
    const [favorite, setFavorite] = useState(false);
    const [timeLeft, setTimeLeft] = useState(600); // 10 minutes in seconds
    const [showSidebar, setShowSidebar] = useState(true); // Initial state for desktop view
    const [showWarning, setShowWarning] = useState(false); // State to show warning banner

    useEffect(() => {
        // Fetch exam data from API
        const fetchExam = async () => {
            try {
                const data = await examApi.fetchExamData(examId);
                setExamData(data);
            } catch (error) {
                console.error('Error fetching exam data:', error);
            }
        };

        fetchExam();
    }, [examId]);

    useEffect(() => {
        if (!examData) return;

        const timer = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000);

        // Clear interval on component unmount
        return () => clearInterval(timer);
    }, [examData]);

    const handleAnswerSelect = (optionIndex) => {
        const newAnswers = [...answers];
        newAnswers[currentQuestionIndex] = optionIndex;
        setAnswers(newAnswers);
        setShowWarning(false); // Hide warning when an option is selected
    };

    const handleNextQuestion = () => {
        if (!examData || currentQuestionIndex >= examData.questions.length - 1) return;

        if (answers[currentQuestionIndex] === undefined) {
            setShowWarning(true); // Show warning if no option selected
            return;
        }

        setCurrentQuestionIndex(prevIndex => prevIndex + 1);
    };

    const handlePrevQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(prevIndex => prevIndex - 1);
        }
    };

    const handleSubmitAnswers = () => {
        // Handle submission of answers here
        console.log('Submitted answers:', answers);
        // Example authentication check
        const isAuthenticated = true; // Replace with your authentication logic
        if (isAuthenticated) {
            navigate('/dashboard');
        } else {
            navigate('/login'); // Redirect to login page if not authenticated
        }
    };

    const handleLike = () => {
        setLiked(true);
        setDisliked(false); // Reset dislike if liked is clicked
    };

    const handleDislike = () => {
        setDisliked(true);
        setLiked(false); // Reset like if disliked is clicked
    };

    const handleFavorite = () => {
        setFavorite(prevFavorite => !prevFavorite);
    };

    const handleNotesChange = (event) => {
        setNotes(event.target.value);
    };

    // Convert seconds to minutes and seconds for display
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    if (!examData) {
        return <div>Loading...</div>; // Replace with your loader component or message
    }

    return (
        <Container fluid className="exam-container">
            {showWarning && (
                <Alert variant="warning" className="warning-banner">
                    Please select an option before proceeding.
                </Alert>
            )}
            <Row>
                {/* Main Question and Options Section */}
                <Col lg={showSidebar ? 8 : 12} md={12} sm={12}>
                    <div className="exam-section">
                        <div className="exam-timer">
                            <h3>Time Left: {formatTime(timeLeft)}</h3>
                        </div>
                        <div className="exam-question">
                            <h2>Question {examData.questions[currentQuestionIndex].id}</h2>
                            <p>{examData.questions[currentQuestionIndex].text}</p>
                        </div>
                        <div className="exam-options">
                            {examData.questions[currentQuestionIndex].options.map((option, index) => (
                                <div key={index} className="exam-option">
                                    <InputGroup>
                                        <InputGroup.Radio
                                            id={`option${index}`}
                                            name={`question${currentQuestionIndex}`}
                                            checked={answers[currentQuestionIndex] === index}
                                            onChange={() => handleAnswerSelect(index)}
                                        />
                                        <FormControl readOnly value={option} />
                                    </InputGroup>
                                </div>
                            ))}
                        </div>
                        <div className="exam-navigation">
                            {currentQuestionIndex !== 0 && (
                                <Button variant="primary" className="exam-nav-btn" onClick={handlePrevQuestion}>
                                    Back
                                </Button>
                            )}
                            {currentQuestionIndex !== examData.questions.length - 1 ? (
                                <Button variant="primary" className="exam-nav-btn" onClick={handleNextQuestion}>
                                    Next
                                </Button>
                            ) : (
                                <Button variant="primary" className="exam-nav-btn" onClick={handleSubmitAnswers}>
                                    Submit Answers
                                </Button>
                            )}
                        </div>
                    </div>
                </Col>
                {/* Sidebar Section (Like, Dislike, Notes) */}
                {showSidebar && (
                    <Col lg={4} md={12} sm={12}>
                        <div className="exam-sidebar">
                            <div className="exam-feedback">
                                <Button
                                    variant={liked ? 'primary' : 'outline-primary'}
                                    className="exam-like"
                                    onClick={handleLike}
                                >
                                    <FaThumbsUp className="mr-2" />
                                    Like
                                </Button>
                                <Button
                                    variant={disliked ? 'danger' : 'outline-danger'}
                                    className="exam-dislike"
                                    onClick={handleDislike}
                                >
                                    <FaThumbsDown className="mr-2" />
                                    Dislike
                                </Button>
                                <Button
                                    variant={favorite ? 'warning' : 'outline-warning'}
                                    className="exam-favorite"
                                    onClick={handleFavorite}
                                >
                                    <FaHeart className="mr-2" />
                                    Favorite
                                </Button>
                            </div>
                            <div className="exam-notes">
                                <FormControl
                                    as="textarea"
                                    className="exam-notes-textarea"
                                    placeholder="Enter your notes..."
                                    value={notes}
                                    onChange={handleNotesChange}
                                />
                            </div>
                        </div>
                    </Col>
                )}
            </Row>
            {/* Show/hide sidebar toggle button for mobile and tablet */}
            {!showSidebar && (
                <Button
                    variant="primary"
                    className="toggle-sidebar-btn"
                    onClick={() => setShowSidebar(true)}
                >
                    Show Sidebar
                </Button>
            )}
        </Container>
    );
};

export default QuestionPage;
