// src/components/Exam/api.js
import AWS from 'aws-sdk';
import { signApiRequest } from '../../config/awsConfig';

export const fetchExamNames = async () => {
    const apiGateway = new AWS.APIGateway();
    const params = {
        restApiId: '0dzl38b7t9', 
        resourceId: 'ty1mpg', 
        httpMethod: 'GET',
    };

    try {
        const response = await signApiRequest(params);
        const data = JSON.parse(response.body);
        return data.exams;
    } catch (error) {
        console.error('Error fetching exam names:', error);
        // Handle error gracefully
    }
};

export const fetchExamDetails = async (examName) => {
    const apiGateway = new AWS.APIGateway();
    const encodedExamName = encodeURIComponent(examName); // URL-encode the exam name
    const pathWithQueryString = `/examSchema?examName=${encodedExamName}`;
    const params = {
        restApiId: 'p728vtfvu4',
        resourceId: '1krcfl',
        httpMethod: 'GET',
        pathWithQueryString: pathWithQueryString
    };

    try {
        
        const response = await signApiRequest(params);
        const data = JSON.parse(response.body);
        return data; // Or extract specific details as needed
    } catch (error) {
        console.error('Error fetching exam details:', error);
        
    }
};

export const createTest = async (email, testDetails) => {
    const apiGateway = new AWS.APIGateway();
    const userId = encodeURIComponent(email); // URL-encode the email
    const pathWithQueryString = `/examSchema?userId=${userId}`;
    
    const params = {
        restApiId: 'qeo80y41rh',  // Replace with your actual RestApiId
        resourceId: '1rj079',  // Replace with your actual ResourceId
        httpMethod: 'POST',
        pathWithQueryString: pathWithQueryString,
        body: JSON.stringify(testDetails), // Send testDetails as JSON
        headers: {
            'Content-Type': 'application/json',
        }
    };

    try {
        const response = await signApiRequest(params);
        const data = JSON.parse(response.body);
        return data; // Return the response data
    } catch (error) {
        console.error('Error creating test:', error);
        return {}
    }
};

export const getAllTests = async (emailId) => {
    const apiGateway = new AWS.APIGateway();
    const encodedemailId = encodeURIComponent(emailId); // URL-encode the exam name
    const pathWithQueryString = `/examSchema?examName=${encodedemailId}`;
    const params = {
        restApiId: '',
        resourceId: '1krcfl',
        httpMethod: 'GET',
        pathWithQueryString: pathWithQueryString
    };

    try {
        
        const response = await signApiRequest(params);
        const data = JSON.parse(response.body);
        return data; // Or extract specific details as needed
    } catch (error) {
        console.error('Error fetching test details :', error);
        return {
            "tests": [
              {
                "testId": "<uuid>",
                "testDetails": "CAT",
                "creationTime": "timestamp",
                "lastUpdateTime": "timestamp",
                "status": "In progress",
                "progress": "7/50"
              },
              {
                "testId": "<uuid>",
                "testDetails": "GMAT",
                "creationTime": "timestamp",
                "lastUpdateTime": "timestamp",
                "status": "Not started"
              },
              {
                "testId": "<uuid>",
                "testDetails": "CAT_recentexam",
                "creationTime": "timestamp",
                "lastUpdateTime": "timestamp",
                "status": "Completed",
                 "score": "47/50",
                 "Incorrect questions": [7, 41, 43],
              }
            ]
          }
          
    }
};

export default {fetchExamNames, fetchExamDetails, createTest, getAllTests};