import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import '../../assets/css/styles.css';
const ExamDetails = () => {
    const { id } = useParams();

    return (
        <Container className="p-5">
            <Row>
                <Col>
                    <h2>Exam Details</h2>
                    <p>Exam ID: {id}</p>
                    <Button variant="primary">Start Exam</Button>
                </Col>
            </Row>
        </Container>
    );
};

export default ExamDetails;
