import React from 'react';
import ExamHeader from './ExamHeader';
import QuestionPage from './QuestionPage';
import ExamNavigation from './ExamNavigation';
import NotesPanel from './NotesPanel';
import TermsAndConditions from './TermsAndConditions';
import '../../assets/css/styles.css';
const ExamPage = () => {
    // Dummy data for exam
    const examData = {
        examName: 'Sample Exam',
        questions: [
            { id: 1, question: 'Sample Question 1', options: ['Option A', 'Option B', 'Option C', 'Option D'], correctAnswer: 2 },
            { id: 2, question: 'Sample Question 2', options: ['Option A', 'Option B', 'Option C', 'Option D'], correctAnswer: 1 },
            // Add more questions as needed
        ],
        duration: 15, // in minutes
    };

    // Dummy data for notes
    const initialNotes = '';

    // State variables for exam state
    const [examStarted, setExamStarted] = React.useState(false);
    const [termsAccepted, setTermsAccepted] = React.useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
    const [notes, setNotes] = React.useState(initialNotes);

    // Function to handle terms acceptance
    const handleTermsAcceptance = () => {
        setTermsAccepted(true);
    };

    // Function to handle starting the exam
    const startExam = () => {
        setExamStarted(true);
    };

    // Function to handle moving to next question
    const moveToNextQuestion = () => {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    };

    // Function to handle moving to previous question
    const moveToPreviousQuestion = () => {
        setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    };

    return (
        <div className="exam-container">
            <ExamHeader examName={examData.examName} duration={examData.duration} examStarted={examStarted} />
            {!examStarted && !termsAccepted && <TermsAndConditions onAccept={handleTermsAcceptance} />}
            {examStarted && (
                <div className="exam-main">
                    <div className="exam-content">
                        <QuestionPage
                            question={examData.questions[currentQuestionIndex]}
                            onNext={moveToNextQuestion}
                            onPrev={moveToPreviousQuestion}
                        />
                        <ExamNavigation
                            currentQuestion={currentQuestionIndex + 1}
                            totalQuestions={examData.questions.length}
                        />
                    </div>
                    <NotesPanel notes={notes} onChange={(newNotes) => setNotes(newNotes)} />
                </div>
            )}
        </div>
    );
};

export default ExamPage;
