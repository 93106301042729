// src/components/Exam/TermsAndConditions.js

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../../assets/css/styles.css';
import { Container, Row, Col, Button } from 'react-bootstrap'; // Import Bootstrap components
import api from './api'; // Import api module

const TermsAndConditions = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [agreed, setAgreed] = useState(false);

    const handleAgree = () => {
        setAgreed(true);
    };

    const handleStartExam = async () => {
        try {
            await api.fetchExamData(id); // Call fetchExamData function to simulate fetching exam data
            navigate(`/exam/${id}/questions`);
        } catch (error) {
            console.error('Error starting exam:', error);
        }
    };

    return (
        <Container fluid className="terms-container d-flex align-items-center justify-content-center">
            <Row className="justify-content-center">
                <Col xs={12} md={8} lg={6}>
                    <div className="terms-card p-4">
                        <h2 className="text-center mb-4">Terms and Conditions</h2>
                        <p className="text-center mb-4">
                            Please read and agree to the terms and conditions before starting the exam.
                        </p>
                        {!agreed && (
                            <Button variant="primary" className="btn-block" onClick={handleAgree}>I Agree</Button>
                        )}
                        {agreed && (
                            <Button variant="success" className="btn-block" onClick={handleStartExam}>Start Exam</Button>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default TermsAndConditions;
