import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/styles.css';
const NotesPanel = ({ notes, onChange }) => {
    const handleNotesChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <div className="notes-panel">
            <textarea value={notes} onChange={handleNotesChange} placeholder="Write your notes here..." />
        </div>
    );
};

NotesPanel.propTypes = {
    notes: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default NotesPanel;
