// src/components/Exam/api.js

// Mock function to fetch exam data
const fetchExamData = async (examId) => {
    // Replace with actual API call logic
    return new Promise((resolve, reject) => {
        // Simulate fetching data
        setTimeout(() => {
            const mockExamData = {
                id: examId,
                questions: [
                    { id: 1, text: 'Question 1?', options: ['Option 1', 'Option 2', 'Option 3'] },
                    { id: 2, text: 'Question 2?', options: ['Option A', 'Option B', 'Option C'] },
                    // Add more questions as needed
                ]
            };
            resolve(mockExamData);
        }, 1000); // Simulating delay of 1 second
    });
};

export default { fetchExamData };